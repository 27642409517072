body {
  margin: 0;
  font-family: "CircularStd";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  border-color: white;
  font-size: 16px;
}

html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

@media (min-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
  h1,
  .h1 {
    font-size: calc(1.375rem + 1.5vw);
  }

  h3,
  .h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
  /* h4,
  .h4 {
    font-size: calc(1.275rem + 0.3vw);
  } */
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
  }
  .display-2 {
    font-size: calc(1.675rem + 5.1vw);
  }
  .display-3 {
    font-size: calc(1.575rem + 3.9vw);
  }
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
  }
  .close {
    font-size: calc(1.275rem + 0.3vw);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  /* background-color: rgba(0, 0, 0, .5);
	box-shadow: 0 0 1px rgba(255, 255, 255, .5); */
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.884);
}

h4 {
  word-wrap: break-word;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: none;
}

.standard-border {
  border-style: solid;
  border-width: thin;
  border-collapse: collapse;
}

.centering-col {
  padding-left: 0%;
  padding-right: 0%;
  text-align: center;
}

.back-button {
  padding: 1vh 0 1vh 0;
  border-left: none;
  border-top: none;
  border-bottom: solid thin black;
  border-right: solid thin white;
}

.back-button:hover {
  color: black;
  border-right: solid thin black;
  background-color: white;
  cursor: pointer;
}

.no-padding {
  padding-left: 0% !important;
  padding-right: 0% !important;
}

.hhaw-button {
  border-radius: 20px 20px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: calc(0.5rem + 2vmin);
}

.invert-color {
  color: white;
  background-color: black;
}

.invert-color:hover {
  color: black;
  background-color: white;
}

input {
  color: white !important;
  background-color: black !important;
}

input[type="text"] {
  width: 100%;
  min-height: 3vh;
  resize: horizontal;
  resize: vertical;
  overflow: auto;
}

/* typeahead overrides */

.rbt {
  font-size: calc(1rem + 1vw);
}

.rbt > .form-control {
  background-color: black !important;
}
.rbt-token {
  color: black !important;
  background-color: #ec8ba0 !important;
}

/* TODO we only need this because of the input rule, can we do this smarter? */
.rbt-input-hint {
  background-color: transparent !important;
}
