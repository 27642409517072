.accordion-card {
  background-color: black;
  border-color: white;
  color: white;
}

.accordion-card-selected {
  color: black;
  background-color: white;
}

.card-content {
  background-color: #ec8ba0;
  color: black;
  border-top: black thin solid;
  text-align: justify;
}

.card-content-anchor-div {
  text-align: center;
}

.card-content-anchor {
  color: black !important;
}

.rd-invert-color {
  color: black;
}

.rd-invert-color:hover {
  background-color: white;
}
