.tip {
  margin: 1vh 0% 2% 2%;
  padding-bottom: 2vh;
  word-wrap: dashed;
}

.tip-list {
  list-style: none;
  padding-left: 0%;
}

.tip-list-wrapper {
  padding-top: 3%;
}
