.story-header {
  text-align: left;
  background-color: white;
  color: black;
}

.story-header h1 {
  margin-bottom: 0;
}

.story-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* overides */

.story-tabs .report-content {
  width: 100%;
  background-color: pink;
  text-align: justify;
  padding: 5%;
  font-size: calc(0.4rem + 1.8vmin);
  overflow-y: auto;
  display: flex;
  flex: 1;
  justify-content: center;
}
