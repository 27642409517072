.rd-header {
  font-size: calc(2rem + 3vw + 3vh);
  font-weight: 300;
  line-height: 1.2;
  text-align: left;
}

.rd-row {
  margin: 1vh 1vw 1vh 1vw;
}

.rd-col-1 {
  text-align: left;
  background-color: #e8392e;
}

.rd-left-square {
  padding: 0;
  margin: 0;
  display: flex;
  width: calc(20vh + 25vw);
  height: calc(20vh + 25vw);
  text-align: left;
}

.rd-right-square {
  padding: 0;
  margin: 0;
  display: flex;
  width: calc((20vh + 25vw) / 2);
  height: calc((20vh + 25vw) / 2);
  text-align: left;
}

.rd-circle {
  margin: 0;
  flex: 1 0 auto;
  border-radius: 50%;
  color: black;
  position: relative;
}

.rd-circle:before {
  content: "";
  float: left;
  padding-top: 100%;
}

.rd-circle h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: calc(0.5rem + 2.8vw);
  z-index: 400;
}

.rd-col-2 {
  text-align: left;
}

.rd-circle h3 {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -10%);
  margin: 0;
  font-size: calc(0.5rem + 1.5vw);
  z-index: 400;
}

.blue-circle-overflow h3 {
  font-size: calc(0.4rem + 1.1vw) !important;
}

.pink-circle-overflow h3 {
  font-size: calc(0.4rem + 1.5vw) !important;
}

/* responsive */

@media (max-width: 1440px) and (min-width: 1024px) {
  .rd-left-square {
    width: calc(15vh + 30vw);
    height: calc(15vh + 30vw);
  }

  .rd-right-square {
    width: calc((15vh + 30vw) / 2);
    height: calc((15vh + 30vw) / 2);
  }

  .rd-circle h1 {
    font-size: calc(0.2rem + 3vw);
  }

  .rd-circle h3 {
    font-size: calc(0.2rem + 1.5vw);
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .rd-left-square {
    width: calc(20vh + 15vw);
    height: calc(20vh + 15vw);
  }

  .rd-right-square {
    width: calc((20vh + 15vw) / 2);
    height: calc((20vh + 15vw) / 2);
  }

  .rd-circle h1 {
    font-size: calc(0.2rem + 2.5vw);
  }

  .blue-circle-overflow h3 {
    font-size: calc(0.2rem + 1.3vw) !important;
  }

  .pink-circle-overflow h3 {
    font-size: calc(0.2rem + 1.5vw) !important;
  }

  .rd-circle h3 {
    font-size: calc(0.2rem + 2vw);
  }
}
