.tabbed-report {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.report-content {
  font-size: 1rem;
  overflow-x: hidden;
  text-align: justify;
  padding: 2% 1.905cm 2% 1.905cm;
  background-color: white;
  color: black;
  width: 1000px;
  height: 100%;
}

/* should throw these into the parser */
.report-content a {
  color: black;
  text-align: left;
}

.report-content a:hover {
  color: blue;
}

.report-content sup {
  color: black;
}

.report-content th {
  font-weight: normal;
  font-size: inherit;
  padding: 2%;
  text-align: justify;
}

.report-content tbody {
  display: none;
}

.report-content h1,
.report-content h2,
.report-content h3,
.report-content h4 {
  text-align: left;
}

.rd-invert-color {
  color: black;
}

.rd-invert-color:hover {
  background-color: white;
}

.report-nav {
  background-color: white;
  color: black;
}

.report-nav-item {
  color: black;
  border-color: black !important;
  font-size: calc(1rem + 0.7vh);
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

@media (max-width: 1440px) {
  .report-nav-item {
    font-size: calc(1rem + 0.3vh);
  }
}

.report-nav-item:hover {
  background-color: black;
  color: white;
}

.nav-link.active {
  background-color: black !important;
  color: white !important;
}
