/* description on the left */

.header-text {
  text-align: right;
}

.dimensions-header {
  height: 100vh;
  text-align: center;
}

.dimensions-title {
  min-height: 15%;
}

.dimensions-title h1 {
  margin: 2% 8% 2% 2%;
}

.dimensions-description-wrapper {
  /* min-height: 85%; */
  flex: 1;
}

.dimensions-description {
  padding-left: 15%;
}

.dimensions-description h2,
.dimensions-description h3 {
  margin: 5% 10% 5% 0%;
}

.dimensions-button-row {
  position: absolute;
  bottom: 10%;
  left: 4%;
  min-width: 100%;
}
@media (max-width: 768px) {
  .dimensions-button-row {
    left: 10%;
  }
}

@media (max-width: 425px) {
  .dimensions-button-row {
    left: 4%;
  }
}

/* table */

.dimension-brief {
  text-align: left;
  font-size: calc(1rem + 0.6vw);
  min-height: 40vh;
}

table {
  height: 100vh;
}

th {
  width: 25%;
  text-align: left;
}

tbody tr {
  height: 40vh;
}

thead tr {
  height: 10%;
}

#emotional.selected-dimension {
  color: #da0031;
}

#physical.selected-dimension {
  color: #ec8ba0;
}

#social.selected-dimension {
  color: #5664cb;
}

#spiritual.selected-dimension {
  color: #00953b;
}

#intellectual.selected-dimension {
  color: #f0b41e;
}

#financial.selected-dimension {
  color: #0038f4;
}

#vocational.selected-dimension {
  color: #e67504;
}

#environmental.selected-dimension {
  color: #66ccff;
}

@media (max-width: 425px) {
  th,
  .th {
    font-size: calc(0.6rem + 0.9vw);
  }
}

@media (min-width: 768px) {
  h1,
  .h1 {
    font-size: calc(1.5rem + 1vw);
  }
  h3,
  .h3 {
    font-size: calc(1rem + 0.6vw);
  }
  th,
  .th {
    font-size: calc(0.4rem + 1vw);
  }
}

@media (min-width: 1024px) {
  th,
  .th {
    font-size: calc(0.6rem + 0.9vw);
  }
}
