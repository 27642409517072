.playboard {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.hidden-titlebox {
  border-radius: 5rem 0 5rem 5rem;
  height: 50%;
  width: 70%;
  position: absolute;
  top: 30%;
  left: 15%;
  z-index: 0;

  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hidden-titlebox > .row,
.col {
  margin: 0;
}

.hidden-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hidden-title h2 {
  text-align: left;
  margin-left: 1%;
  font-size: calc(0.5rem + 2.5vmax);
}

.circle-button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 5%;
}

.circle-button {
  z-index: 100;
  height: 9vmax;
  width: 9vmax;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.circle-button h3 {
  font-size: calc(0.5rem + 1.5vmax);
}
