.preview-buffer {
  min-height: 10vh;
}

.preview-row {
  margin-bottom: 2%;
}

.preview-row > .col {
  margin-left: 2%;
  margin-right: 2%;
  height: 18vh;
  text-align: left;
  position: relative;
}

.preview-count {
  position: absolute;
  left: 0;
  min-width: 45%;
  margin-bottom: 2vh;
  border-bottom: white thin solid;
  border-right: white thin solid;
}

.preview-count h3 {
  margin-left: 0.1vw;
  margin-bottom: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.preview-caption {
  font-size: calc(0.6rem + 1.2vmin);
  position: absolute;
  top: 25%;
  left: 0.5vw;
  animation: fadeIn ease 2s;
}

@media (max-width: 1280px) {
  .preview-caption {
    font-size: calc(0.5rem + 1vmin);
  }
}

@media (max-width: 1024px) {
  .preview-caption {
    font-size: calc(0.4rem + 1vmin);
  }
}

.data-display {
  padding: 2%;
}

.data-display-wrapper {
  margin-bottom: 2%;
  padding: 2vh 0 2vh 0;
}

.btn-default.dropdown-toggle .text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 20px;
  display: inline-block;
  vertical-align: middle;
}

.x-toggle {
  padding-top: 2%;
}

.dataviz-plot {
  height: 100%;
  width: 100%;
}

.dataviz-spinner {
  margin-top: 30vh;
}

.dataviz-break {
  height: 20vh;
  background-color: white;
  color: black;
  text-align: left;
  padding-top: 3vh;
}

.sankey-placeholder {
  height: 50vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sankey-header {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sankey-header h3 {
  margin-top: 1vh;
  margin-bottom: 1vh;
  margin-left: 2vw;
}

.sankey-category {
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sankey-category h4 {
  margin: 0;
}

.sankey-category-active {
  background-color: white;
  color: black;
  border: 0 !important;
}
