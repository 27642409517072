.title-box {
  border-right: thin solid;
  border-bottom: thin solid;
  /* padding-bottom: 10%; */
  text-align: center;
  justify-content: center;
  /* flex: 1; */
  height: 76vh;
}

.title {
  font-size: calc(2rem + 3vw + 3vh);
  font-weight: 300;
  line-height: 1.2;
  padding-left: 5%;
  padding-top: 5%;
  margin-bottom: -5%;
}

@media (max-width: 768px) {
  .title {
    font-size: calc(2rem + 3vw +4vh);
  }
}

.logo {
  position: absolute;
  top: 1.8%;
  right: 5%;
}

.logo > img {
  max-width: 11vw;
  height: auto;
  content: url("/logo/logo.png");
}

@media (max-width: 425px) {
  .logo {
    top: 25%;
    right: 37.5%;
  }
  .logo > img {
    max-width: 25vw;
  }
}

.title-box-button {
  position: relative;
  top: 5%;
  font-size: calc(1.275rem + 0.5vw);
}

@media (max-width: 768px) {
  .title-box-button {
    font-size: calc(1rem + 0.5vw);
  }
}

.title-box-button-row {
  align-items: center;
  justify-content: center;
}
