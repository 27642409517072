.navbar-member {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  word-wrap: break-word;
  height: 11.6vw;
  margin-left: -1px;
  margin-right: -1px;
}

.navbar-active {
  background-color: white;
  color: black;
  border: white;
}

.navbar-member h5 {
  margin: 2%;
}

@media (max-width: 1024px) {
  .navbar-member h5 {
    font-size: calc(0.3rem + 0.7vw);
  }
}

@media (max-width: 425px) {
  .navbar-member {
    height: 20vw;
  }
}

.navbar-member:hover {
  color: black;
  background-color: white;
  cursor: pointer;
}
