/* .dimensions-nav-container {
  flex: 0 0 auto;
} */

.dimensions-row {
  height: 8vh;

  margin-left: 0;
}

.dimension-text {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  /* margin-left: 3%;
  margin-right: 3%; */

  display: flex;
  color: white;
  align-items: center;

  justify-content: center;
}

#dimension-8 {
  border-right: thin white solid;
}

.dimension-text:hover {
  border-color: white;
}

.dimension-text:hover {
  text-decoration: none;
  color: black;
  background-color: white;
}

@media (max-width: 375px) {
  h4,
  .h4 {
    font-size: calc(0.5rem + 0.8vw);
  }
}

@media (min-width: 425px) {
  h4,
  .h4 {
    font-size: calc(0.6rem + 1vw);
  }
}

@media (min-width: 768px) {
  h4,
  .h4 {
    font-size: calc(0.5rem + 0.4vw);
  }
}

@media (min-width: 1024px) {
  h4,
  .h4 {
    font-size: calc(0.6rem + 0.5vw);
  }
}

@media (min-width: 1440px) {
  h4,
  .h4 {
    font-size: calc(0.7rem + 0.6vw);
  }
}
