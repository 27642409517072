.section-header {
  background-color: white;
  color: black;
  padding: 1% 0% 0.5% 0%;
  border-right: thin black;
  border-left: thin black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3em;
}

.section-header h2 {
  word-wrap: break-word;
  margin: 0;
}

.section-number {
  background-color: white;
  color: black;
  padding: 1% 0% 1% 0%;
  margin-right: 1%;
  border-right: thin black;
  border-top: thin black;
}

.section-progress-element {
  padding-left: 2vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.section-progress-list {
  padding-left: 2vh;
}

.current-subsection-wrapper {
  border-style: solid;
  border-width: thin;
  border-collapse: collapse;
  border-right: none;
}

.finish-modal {
  color: black;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}
