.landing-full {
  display: block;
}

.modal-block-mobile {
  display: none;
}

.hhaw-container {
  overflow: hidden;
  text-align: center;
  min-height: 100vh;

  align-items: left;
  justify-content: center;
}

.landing-col {
  height: 100vh;
}

/* playboard */
.landing-content {
  height: calc(100% - 11.66vw);
}

/* story bit */

.our-story-header {
  font-size: calc(3rem + 3vw + 3vh);
  line-height: 1.2;
  margin-top: 8%;
  margin-left: 2%;
}

.our-story-header-end {
  font-size: calc(1rem + 1.3vw);
  line-height: 1.2;
  margin: 8% 0 3% 2%;
}

.landing-story-row {
  margin: 1vh 4vw 15vh;
  height: 80vh;
}

.landing-story-col-1 {
  text-align: left;
}

.landing-story-col-1 h3 {
  font-size: 1.5rem;
}

/* carousel */

.landing-story-carousel {
  width: 100%;
  min-height: 100%;
}

.landing-story-carousel-item {
  height: 100%;
}

.landing-story-col-2 {
  text-align: left;
}

.landing-story-col-2 p {
  margin: 10% 10% 2%;
  font-size: calc(0.4rem + 1.8vmin);
}

.landing-story-col-2 h3 {
  margin: 2% 10px;
}

.landing-story-col-2 .hhaw-button {
  text-align: center;
  border-radius: 25px 25px;
}

.landing-story-col-2 .hhaw-button:hover {
  color: black;
}

.story-logo {
  position: absolute;
  top: 50%;
  left: 10%;
}

.story-logo > img {
  min-width: 18vw;
  content: url("/logo/logo.svg");
  -webkit-transform: scaleX(-1) rotate(-20deg);
  transform: scaleX(-1) rotate(-20deg);
}

@media (max-width: 1440px) and (min-width: 1024px) {
  .landing-story-row p {
    font-size: calc(0.4rem + 1.5vmin);
  }

  .end-slide-right h2 {
    font-size: calc(1rem + 1.4vw);
    margin-bottom: 2vw;
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .landing-story-row p {
    font-size: calc(0.4rem + 1.3vmin);
  }

  .end-slide-right h2 {
    font-size: calc(1rem + 1vw);
    margin-bottom: 15vw;
  }
}
