.question-container {
  padding-left: 0% !important;
  padding-right: 0% !important;
  border-style: solid;
  border-width: thin;
}

/* overiding progress bar from boostrap */
.progress {
  background-color: white;
}

.progress-bar {
  background-color: pink;
  color: black;
}

/* div where the question mark button lives */
.question-box {
  position: absolute;
  right: 0;
  width: calc(2rem + 2vw);
  height: calc(2rem + 2vw);
  color: black;
  background-color: white;
  padding: 0.3rem 0 0 0;
  text-align: center;
  border-color: white;
  border-collapse: collapse !important;
}

.question-box svg {
  max-height: calc(1.2rem + 2vw);
  max-width: calc(1.3rem + 2vw);
}

.question-box:hover {
  color: white;
  background-color: black;
}

/* where the prompt goes */
.header {
  height: 35vh;
  border-bottom: thin solid;
  position: relative;
  border-right: 0 !important;
  border-left: 0 !important;
}

.prompt {
  margin: 1vh 1% 0% 2%;
  font-size: calc(1.2rem + 1.1vw);
  font-weight: 275;
}

.feedback-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.feedback-div h5 {
  font-size: calc(0.375rem + 1.3vw);
  margin-bottom: 0;
}

/* next or finish button */
.next-div {
  position: absolute;
  bottom: 2vh;
  right: 3vh;
  width: 8vw;
  height: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;
}

.next-div h5 {
  font-size: calc(0.375rem + 1.3vw);
  margin-bottom: 0;
}

.next-div:hover {
  color: black;
  background-color: white;
  cursor: pointer;
}

/* this is where the possible answers pop up */
.answer-box {
  min-height: 50vh;
  overflow-y: visible;
  border-right: 0 !important;
  border-left: 0 !important;
}

.drop-mark-list {
  list-style: none;
  height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
}

.input-field {
  padding: 5% 0% 5% 0%;
}

.mark-button-col {
  padding-left: 0%;
  padding-right: 0%;
  margin: -1px 0 0 -1px;
  cursor: pointer;
}

.mark-button-col-xs {
  max-width: 20%;
}

.mark-button-row {
  padding-left: 5vh;
  padding-right: 5vh;
}

.mark-button {
  display: flex;
  flex-direction: column;
  height: 16vh;
  overflow: auto;
  text-align: center;
  margin: 0;
  word-wrap: break-word;
  font-size: calc(0.3rem + 0.7vw);
  overflow-y: hidden;
}

.mark-button-sm {
  height: 10vh;
  font-size: calc(0.4rem + 0.5vw);
}

.mark-button-mark-circle {
  height: 11vh;
  font-size: calc(0.3rem + 0.5vw);
}

.mark-button-xs {
  height: 8vh;
  font-size: calc(0.4rem + 0.5vw);
}

.mark-button p {
  margin: 0% 2% 3% 2%;
  hyphens: auto;
  max-height: 40%;
}

.sprite {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  width: 65%;
  max-height: 60%;
  padding: 7%;
}

.sprite-sm {
  width: 35%;
  /* max-height: 60%; */
  padding: 2%;
}

.highlight {
  background-color: #5de8dc;
}

.highlight-circle {
  background-color: #1d48f2;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Opera and Firefox */
}

.percentage-legend-box {
  height: 1vh;
  width: 1vh;
}

.percentage-input-list {
  font-size: 10;
  list-style: none;
  padding-left: 0;
  padding-top: 10%;
}

.percentage-text-input {
  color: white;
  background-color: black;
  border: 0;
  border-bottom: white thin solid;
  width: 30px;
  height: 20px;
}

.goog-tooltip {
  color: black !important;
}

input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}
