.top-semicircle {
  position: absolute;
  overflow: hidden;

  height: 50%;
  width: 100%;

  border-top-left-radius: 10000px;
  border-top-right-radius: 10000px;

  opacity: 1;
  z-index: 300;
}

.left-semicircle {
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  border-bottom-left-radius: 10000px;
  border-top-left-radius: 10000px;
  opacity: 1;
  z-index: 100;
}

.right-semicircle {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  border-bottom-right-radius: 10000px;
  border-top-right-radius: 10000px;
  z-index: 200;
}
