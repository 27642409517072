.accordion-header {
  text-align: left;
  background-color: white;
  color: black;
}

.accordion-header h1 {
  padding-left: 1%;
}

.research-atf {
  max-height: calc(100vh - 11.66vw);
}

@media (max-width: 425px) {
  .research-atf {
    max-height: calc(100vh - 20vw);
  }
}

.research-atf > .col {
  max-height: 100%;
}

.hhaw-accordion-div {
  overflow-y: auto;
}

.break {
  background-image: url("/break.png");
  min-height: 40vh;
}
